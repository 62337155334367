import { mapHelper } from "@/utils/common.js";

const orderStatus = [
  {
    value: "1",
    label: "待领取",
  },
  // {
  //   value: "2",
  //   label: "已发货",
  // },
  {
    value: "3",
    label: "已完成",
  },
];
const shelfSts = [
  {
    value: 0,
    label: "待上架",
  },
  {
    value: 1,
    label: "上架",
  },
  {
    value: 2,
    label: "下架",
  },
];
const pointsSts = [
  {
    value: 1,
    label: "积分从高到低",
  },
  {
    value: 2,
    label: "积分从低到高",
  },
];
const goodsTypeList = [
  {
    value: 1,
    label: "实物",
  },
  {
    value: 2,
    label: "优惠券",
  },
];
const goodsClassify = [
  {
    value: 1,
    label: "普通商品",
  },
  {
    value: 2,
    label: "志愿商品",
  },
];

const { map: goodsClassifyMap, setOps: setGoodsClassifyOps } =
  mapHelper.setMap(goodsClassify);

const merchantStatus = [
  {
    value: 1,
    label: "审核通过",
  },
  {
    value: 2,
    label: "审核不通过",
  },
  {
    value: 3,
    label: "冻结中",
  },
  {
    value: 4,
    label: "申诉中",
  },
  {
    value: 6,
    label: "申诉失败",
  },
  {
    value: 0,
    label: "待审核",
  },
];

const { map: merchantStatusMap, setOps: merchantStatusOps } =
  mapHelper.setMap(merchantStatus);
// 	商圈类型
const businessTypeops = [
  {
    value: 1,
    label: "商铺",
  },
  {
    value: 2,
    label: "服务者",
  },
];

const { map: businessTypeopsMap, setOps: businessTypeopsOps } =
  mapHelper.setMap(businessTypeops);
// 	经营类型

const manageTypeops = [
  {
    value: 1,
    label: "运动健身",
  },
  {
    value: 2,
    label: "美食",
  },
  {
    value: 3,
    label: "生活服务",
  },
  {
    value: 4,
    label: "文化",
  },
  {
    value: 5,
    label: "学习培训",
  },
  {
    value: 6,
    label: "零售",
  },
  {
    value: 7,
    label: "美容美发",
  },
  {
    value: 8,
    label: "医药",
  },
  {
    value: 0,
    label: "其他",
  },
];

const { map: manageTypeopsMap, setOps: manageTypeopsOps } =
  mapHelper.setMap(manageTypeops);

// 	审核
const handleStatus = [
  {
    value: 1,
    label: "审核通过",
  },
  {
    value: 2,
    label: "审核驳回",
  },
];

const { map: handleStatusMap, setOps: handleStatusOps } =
  mapHelper.setMap(handleStatus);
export {
  orderStatus,
  shelfSts,
  pointsSts,
  goodsTypeList,
  goodsClassifyMap,
  setGoodsClassifyOps,
  merchantStatus,
  merchantStatusMap,
  manageTypeops,
  manageTypeopsMap,
  businessTypeopsMap,
  businessTypeops,
  handleStatusMap,
  handleStatus,
};
